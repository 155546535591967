/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 63.1.0-v202403060645
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqSignalsApiClass {
 
   constructor() {}

  /**
   * @summary Save samples to a signal
   * @param {string} id - The Seeq ID for the signal
   */
  public addSamples(
    body: models.SamplesInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(id))}/samples`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SamplesOutputV1>;
  }

  /**
   * @summary Save samples to a signal, using the source system's identifier
   * @param {string} datasourceClass - The class of the datasource containing the signal
   * @param {string} datasourceId - The ID of the datasource containing the signal
   * @param {string} dataId - The Data ID for the signal
   */
  public addSamplesByDataId(
    body: models.SamplesInputV1,
    {
      datasourceClass,
      datasourceId,
      dataId
    } : {
      datasourceClass: string,
      datasourceId: string,
      dataId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    if (isNil(dataId)) {
      throw new Error("'dataId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/${encodeURIComponent(String(dataId))}/samples`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SamplesOutputV1>;
  }

  /**
   * @summary Archive a signal
   * @param {string} id - The Seeq ID for the signal
   */
  public archiveSignal(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Archive a signal, using the source system's identifier
   * @param {string} datasourceClass - The class of the datasource containing the signal
   * @param {string} datasourceId - The ID of the datasource containing the signal
   * @param {string} dataId - The Data ID for the signal
   */
  public archiveSignalByDataId(
    {
      datasourceClass,
      datasourceId,
      dataId
    } : {
      datasourceClass: string,
      datasourceId: string,
      dataId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    if (isNil(dataId)) {
      throw new Error("'dataId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/${encodeURIComponent(String(dataId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Create a new signal, to be stored in Seeq
   */
  public createSignal(
    body: models.SignalInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/signals`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SignalOutputV1>;
  }

  /**
   * @summary Delete all samples in a signal
   * @param {string} id - The Seeq ID for the signal
   */
  public deleteSamples(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(id))}/samples`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get a sample from a signal
   * @param {string} id - The Seeq ID for the signal
   * @param {string} key - The key in the signal where the sample should be retrieved
   * @param {string} [lookup=Interpolate] - Where to look for a sample relative to the specified key. The options are Interpolate, Before, AtOrBefore, At, AtOrAfter, After
   * @param {string} [keyUnitOfMeasure] - Units to use for the key of each sample. The default does no unit conversion. For time-series, the default is ISO 8601 timestamps, e.g. &#x27;2016-01-01T19:51:32.490Z&#x27;.
   * @param {string} [valueUnitOfMeasure] - Units to use for the value of each sample. The default does no unit conversion.
   */
  public getSample(
    {
      id,
      key,
      lookup,
      keyUnitOfMeasure,
      valueUnitOfMeasure
    } : {
      id: string,
      key: string,
      lookup?: SamplesLookupEnum,
      keyUnitOfMeasure?: string,
      valueUnitOfMeasure?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(key)) {
      throw new Error("'key' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(id))}/sample/${encodeURIComponent(String(key))}`,

      params: omitBy({
        ['lookup']: lookup,
        ['keyUnitOfMeasure']: keyUnitOfMeasure,
        ['valueUnitOfMeasure']: valueUnitOfMeasure
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetSampleOutputV1>;
  }

  /**
   * @summary Get a sample from a signal, using the source system's identifier
   * @param {string} datasourceClass - The class of the datasource containing the signal
   * @param {string} datasourceId - The ID of the datasource containing the signal
   * @param {string} dataId - The Data ID for the signal
   * @param {string} key - The key in the signal where the sample should be retrieved
   * @param {string} [lookup=Interpolate] - Where to look for a sample relative to the specified key. The options are Interpolate, Before, AtOrBefore, At, AtOrAfter, After
   * @param {string} [keyUnitOfMeasure] - Units to use for the key of each sample. The default does no unit conversion. For time-series, the default is ISO 8601 timestamps, e.g. &#x27;2016-01-01T19:51:32.490Z&#x27;.
   * @param {string} [valueUnitOfMeasure] - Units to use for the value of each sample. The default does no unit conversion.
   */
  public getSampleByDataId(
    {
      datasourceClass,
      datasourceId,
      dataId,
      key,
      lookup,
      keyUnitOfMeasure,
      valueUnitOfMeasure
    } : {
      datasourceClass: string,
      datasourceId: string,
      dataId: string,
      key: string,
      lookup?: LookupEnum,
      keyUnitOfMeasure?: string,
      valueUnitOfMeasure?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    if (isNil(dataId)) {
      throw new Error("'dataId' parameter required");
    }

    if (isNil(key)) {
      throw new Error("'key' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/${encodeURIComponent(String(dataId))}/sample/${encodeURIComponent(String(key))}`,

      params: omitBy({
        ['lookup']: lookup,
        ['keyUnitOfMeasure']: keyUnitOfMeasure,
        ['valueUnitOfMeasure']: valueUnitOfMeasure
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetSampleOutputV1>;
  }

  /**
   * @summary Get samples from a signal over an interval
   * @param {string} id - The Seeq ID for the signal
   * @param {string} start - The key at the start of the query interval. The contents vary based on the series type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} end - The key at the end of the query interval. The contents vary based on the series type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} [period=0] - To resample the signal to an evenly-spaced grid, provide the desired resampling period. The default does not perform resampling.
   * @param {boolean} [inflate=false] - Whether to inflate the resulting signal. Inflation adds samples that may not be present in the source historian in two cases. Signals with step discontinuities will have an extra sample at each discontinuity. Also, gaps wider than Max Interpolation will be marked explicitly by invalid values. An inflated signal can be plotted by connecting adjacent samples with straight lines, without needing to consider the signal&#x27;s interpolation method.
   * @param {string} [boundaryValues=Outside] - Choice for handling samples near the start and end of the query interval. The options are Outside, Inclusive, and Interpolate.
   * @param {string} [keyUnitOfMeasure] - Units to use for the key of each sample. The default does no unit conversion. For time-series, the default is ISO 8601 timestamps, e.g. &#x27;2016-01-01T19:51:32.490Z&#x27;.
   * @param {string} [valueUnitOfMeasure] - Units to use for the value of each sample. The default does no unit conversion.
   * @param {number} [limit=1000] - The pagination limit, the total number of samples that will be returned in this page of results
   * @param {string} [continuationToken] - An opaque token used to query for the next page of results. Only use if returned by a previous call.
   */
  public getSamples(
    {
      id,
      start,
      end,
      period,
      inflate,
      boundaryValues,
      keyUnitOfMeasure,
      valueUnitOfMeasure,
      limit,
      continuationToken
    } : {
      id: string,
      start: string,
      end: string,
      period?: string,
      inflate?: boolean,
      boundaryValues?: SamplesBoundaryValuesEnum,
      keyUnitOfMeasure?: string,
      valueUnitOfMeasure?: string,
      limit?: number,
      continuationToken?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(end)) {
      throw new Error("'end' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(id))}/samples`,

      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['period']: period,
        ['inflate']: inflate,
        ['boundaryValues']: boundaryValues,
        ['keyUnitOfMeasure']: keyUnitOfMeasure,
        ['valueUnitOfMeasure']: valueUnitOfMeasure,
        ['limit']: limit,
        ['continuationToken']: continuationToken
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetSamplesOutputV1>;
  }

  /**
   * @summary Get samples from a signal over an interval, using the source system's identifier
   * @param {string} datasourceClass - The class of the datasource containing the signal
   * @param {string} datasourceId - The ID of the datasource containing the signal
   * @param {string} dataId - The Data ID for the signal
   * @param {string} start - The key at the start of the query interval. The contents vary based on the series type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} end - The key at the end of the query interval. The contents vary based on the series type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} [period=0] - To resample the signal to an evenly-spaced grid, provide the desired resampling period. The default does not perform resampling.
   * @param {boolean} [inflate=false] - Whether to inflate the resulting signal. Inflation adds samples that may not be present in the source historian in two cases. Signals with step discontinuities will have an extra sample at each discontinuity. Also, gaps wider than Max Interpolation will be marked explicitly by invalid values. An inflated signal can be plotted by connecting adjacent samples with straight lines, without needing to consider the signal&#x27;s interpolation method.
   * @param {string} [boundaryValues=Outside] - Choice for handling samples near the start and end of the query interval. The options are Outside, Inclusive, and Interpolate.
   * @param {string} [keyUnitOfMeasure] - Units to use for the key of each sample. The default does no unit conversion. For time-series, the default is ISO 8601 timestamps, e.g. &#x27;2016-01-01T19:51:32.490Z&#x27;.
   * @param {string} [valueUnitOfMeasure] - Units to use for the value of each sample. The default does no unit conversion.
   * @param {number} [limit=1000] - The pagination limit, the total number of samples that will be returned in this page of results
   * @param {string} [continuationToken] - An opaque token used to query for the next page of results. Only use if returned by a previous call.
   */
  public getSamplesByDataId(
    {
      datasourceClass,
      datasourceId,
      dataId,
      start,
      end,
      period,
      inflate,
      boundaryValues,
      keyUnitOfMeasure,
      valueUnitOfMeasure,
      limit,
      continuationToken
    } : {
      datasourceClass: string,
      datasourceId: string,
      dataId: string,
      start: string,
      end: string,
      period?: string,
      inflate?: boolean,
      boundaryValues?: BoundaryValuesEnum,
      keyUnitOfMeasure?: string,
      valueUnitOfMeasure?: string,
      limit?: number,
      continuationToken?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    if (isNil(dataId)) {
      throw new Error("'dataId' parameter required");
    }

    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(end)) {
      throw new Error("'end' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/${encodeURIComponent(String(dataId))}/samples`,

      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['period']: period,
        ['inflate']: inflate,
        ['boundaryValues']: boundaryValues,
        ['keyUnitOfMeasure']: keyUnitOfMeasure,
        ['valueUnitOfMeasure']: valueUnitOfMeasure,
        ['limit']: limit,
        ['continuationToken']: continuationToken
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetSamplesOutputV1>;
  }

  /**
   * @summary Get signal
   * @param {string} id - The Seeq ID for the signal
   */
  public getSignal(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SignalOutputV1>;
  }

  /**
   * @summary Get a signal, using the source system's identifier
   * @param {string} datasourceClass - The class of the datasource containing the signal
   * @param {string} datasourceId - The ID of the datasource containing the signal
   * @param {string} dataId - The Data ID for the signal
   */
  public getSignalByDataId(
    {
      datasourceClass,
      datasourceId,
      dataId
    } : {
      datasourceClass: string,
      datasourceId: string,
      dataId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    if (isNil(dataId)) {
      throw new Error("'dataId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/${encodeURIComponent(String(dataId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SignalOutputV1>;
  }

  /**
   * @summary List signals
   * @param {number} [offset=0] - The pagination offset, the index of the first signal that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of signals that will be returned in this page of results
   */
  public getSignals(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/signals`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetSignalsOutputV1>;
  }

  /**
   * @summary Replace any samples to a signal in the same time range
   * @param {string} id - The Seeq ID for the signal
   */
  public putSamples(
    body: models.SamplesOverwriteInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'put',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(id))}/samples`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SamplesOutputV1>;
  }

  /**
   * @summary Replace any samples to a signal in the same time range, using the source system's identifier
   * @param {string} datasourceClass - The class of the datasource containing the signal
   * @param {string} datasourceId - The ID of the datasource containing the signal
   * @param {string} dataId - The Data ID for the signal
   */
  public putSamplesByDataId(
    body: models.SamplesOverwriteInputV1,
    {
      datasourceClass,
      datasourceId,
      dataId
    } : {
      datasourceClass: string,
      datasourceId: string,
      dataId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    if (isNil(dataId)) {
      throw new Error("'dataId' parameter required");
    }

    return getAxiosInstance()({
      method: 'put',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/${encodeURIComponent(String(dataId))}/samples`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SamplesOutputV1>;
  }

  /**
   * @summary Update a signal
   * @param {string} id - The Seeq ID for the signal
   */
  public putSignal(
    body: models.SignalInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SignalOutputV1>;
  }

  /**
   * @summary Create or update a signal, using the source system's identifier
   * @param {string} datasourceClass - The class of the datasource containing the signal
   * @param {string} datasourceId - The ID of the datasource containing the signal
   * @param {string} dataId - The Data ID for the signal
   */
  public putSignalByDataId(
    body: models.SignalInputV1,
    {
      datasourceClass,
      datasourceId,
      dataId
    } : {
      datasourceClass: string,
      datasourceId: string,
      dataId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    if (isNil(dataId)) {
      throw new Error("'dataId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/signals/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/${encodeURIComponent(String(dataId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SignalOutputV1>;
  }

  /**
   * @summary Create or update multiple signals
   */
  public putSignals(
    body: models.PutSignalsInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/signals/batch`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemBatchOutputV1>;
  }

}

export enum SamplesLookupEnum {
    Interpolate = 'Interpolate' as any,
    Before = 'Before' as any,
    AtOrBefore = 'AtOrBefore' as any,
    At = 'At' as any,
    AtOrAfter = 'AtOrAfter' as any,
    After = 'After' as any
}
export enum LookupEnum {
    Interpolate = 'Interpolate' as any,
    Before = 'Before' as any,
    AtOrBefore = 'AtOrBefore' as any,
    At = 'At' as any,
    AtOrAfter = 'AtOrAfter' as any,
    After = 'After' as any
}
export enum SamplesBoundaryValuesEnum {
    Outside = 'Outside' as any,
    Inclusive = 'Inclusive' as any,
    Interpolate = 'Interpolate' as any
}
export enum BoundaryValuesEnum {
    Outside = 'Outside' as any,
    Inclusive = 'Inclusive' as any,
    Interpolate = 'Interpolate' as any
}

export const sqSignalsApi = new sqSignalsApiClass();
